import { graphql, useStaticQuery } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import * as React from "react";
import { useMediaQuery } from "react-responsive";
import Aside from "../../components/Aside";
import Page from "../../components/Page";
import * as P from "../../components/Page/styles";
import SubAreas from "../../components/SubAreas";
import Seo from "../../components/seo";
import Icon from "../../components/Icons/icon";

const RetirementByAge = () => {
  const { background } = useStaticQuery(
    graphql`
      query {
        background: file(relativePath: { eq: "banner.jpg" }) {
          childImageSharp {
            gatsbyImageData(
              width: 1920
              quality: 100
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    `
  );

  const image = getImage(background);
  const isDesktop = useMediaQuery({ minWidth: 768 });

  return (
    <React.Fragment>
      <Page
        title="Aposentadoria por Idade"
        image={image}
        breadcrumb={[
          { page: "/", name: "Início" },
          {
            page: "/aposentadoria-e-auxilios",
            name: "Aposentadoria e Auxílios",
          },
          { page: "/aposentadoria-por-idade", name: "Aposentadoria por Idade" },
        ]}
      >
        {isDesktop && <Aside />}

        <P.Article>
          <p>
            Para se aposentar por idade, o trabalhador urbano deve comprovar
            carência mínimo e também comprovar a idade necessária:
          </p>

          <P.Checklist>
            <li>
              <Icon name="Check" />{" "}
              <span>180 meses de trabalho (carência);</span>
            </li>
            <li>
              <Icon name="Check" /> <span>65 anos de idade se Homens;</span>
            </li>
            <li>
              <Icon name="Check" /> <span>62 anos de Mulher.</span>
            </li>
          </P.Checklist>

          <p>
            A Aposentadoria Rural por Idade é concedida sempre que o trabalhador{" "}
            <b>completa a idade mínima e o período de carência</b> definidos em
            lei. A Aposentadoria Rural por Idade traz os seguintes requisitos:
          </p>

          <P.Checklist>
            <li>
              <Icon name="Check" /> <span>60 anos completos para homens;</span>
            </li>
            <li>
              <Icon name="Check" />{" "}
              <span>55 anos completos para mulheres;</span>
            </li>
            <li>
              <Icon name="Check" />{" "}
              <span>Período de carência de 180 meses.</span>
            </li>
          </P.Checklist>

          <p>
            Um ponto importante é que a legislação reduz a idade legal para
            aposentadoria rural nas 4 categorias de segurados rurais{" "}
            <b>
              (segurado empregado, segurado contribuinte individual, segurado
              trabalhador avulso e segurado especial)
            </b>
            .
          </p>
        </P.Article>

        {!isDesktop && <Aside withContact />}
      </Page>

      <SubAreas />
    </React.Fragment>
  );
};

export const Head = () => (
  <Seo
    title="Aposentadoria por Idade"
    description="Suporte jurídico em Aposentadoria por Idade. Oferecemos orientação para assegurar seus direitos e facilitar o processo de aposentadoria, com atendimento personalizado e eficiente."
  />
);

export default RetirementByAge;
